<template>
  <b-modal
    visible
    scrollable
    centered
    size="s"
    :title="title"
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="d-flex mb-3">
      <b-input
        v-model="phoneConfirmCode"
        :state="!(phoneConfirmCode.length < 4)"
        placeholder="Код из смс"
      />

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="phoneConfirmCode.length < 4"
        class="ml-4 text-nowrap"
        @click="onPhoneConfirmCodeCheck"
      >
        Проверить код
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'CheckPhoneNumberModal',
  props: {

  },
  data() {
    return {
      isOpen: true,
      title: 'Подтверждение номера телефона',
      phoneConfirmCode: '',
    };
  },
  methods: {
    async onPhoneConfirmCodeCheck() {
      try {
        this.$emit('onPhoneConfirmCodeCheck', this.phoneConfirmCode);
      } finally {
        this.onClose();
      }
    },
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
